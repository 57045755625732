import(/* webpackMode: "eager" */ "/codebuild/output/src1391761235/src/chikitsa-website/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/components/Header/Header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContextProvider"] */ "/codebuild/output/src1391761235/src/chikitsa-website/context/context.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProviders"] */ "/codebuild/output/src1391761235/src/chikitsa-website/context/queryProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/context/themeContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "/codebuild/output/src1391761235/src/chikitsa-website/context/ToastContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/@mui/icons-material/Facebook.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/@mui/icons-material/FmdGood.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/@mui/icons-material/Instagram.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/@mui/icons-material/LinkedIn.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/@mui/icons-material/Mail.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/@mui/icons-material/Phone.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/@mui/icons-material/X.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/@mui/material/Badge/index.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1391761235/src/chikitsa-website/node_modules/react-toastify/dist/ReactToastify.css");
